import images from './project.jpg'
import React, { useState, useEffect } from 'react';
import './index.css'
import axios, { Axios } from 'axios';
import moment from 'moment';
const ProjectPage = () => {

    const [allProjects, setAllProjects] = useState([])

    const currentDate = new Date();
    useEffect(() => {
        axios.get(process.env.REACT_APP_BASE_URL + '/api/projects').then(res => {
            const sortedProjects = res.data
                .map(project => ({
                    ...project,
                    startDate: moment(project.startDate).format('YYYY-MM-DD'),
                    endDate: moment(project.endDate).format('YYYY-MM-DD')
                }))
                .sort((a, b) => moment(b.endDate).toDate() - moment(a.endDate).toDate());
            setAllProjects(sortedProjects)
        })
    }, [])
    return (
        <>
            <div  >
                <img src={images} alt="project" style={{ width: '100%', height: 800 }} />
            </div>
            <div class="flex-shrink-0 py-4  text-black-100" style={{ marginTop: 50 }}>
                <div class="jumbotron text-center" >
                    <h3>Project showcase</h3>
                    <br />
                    <p>Below are the list of the projects we have completed and also some projects that are still ongoing!</p>
                </div>
            </div>
            <div class="container mt-20 mb-5 bg-100 ">
                <div class="row">
                    <div class="col-md-10 offset-md-1">

                        <br />
                        <ul class="timeline">
                            {allProjects.map(project => (
                                <li>
                                    <p><b>Start - {project.startDate}</b></p>
                                    <a href={project.link} target='_blank' rel="noopener noreferrer" class="float-right">Title : {project.title}</a>
                                    <p>Client-{project.client}</p>
                                    <p>{project.description}</p>
                                    <p>Contract value : {project.contractValue} Millions</p>
                                    <p><b>Status - {(new Date(project.endDate) < currentDate)
                                        ? <span class="badge-success">End at : {project.endDate}</span>
                                        : <span class=" badge-danger" style={{ color: 'red' }}>Ongoing</span>
                                    }</b></p>
                                    <hr />

                                </li>
                            ))}

                        </ul>
                    </div>
                </div>
            </div>
            <footer id="sticky-footer" class="flex-shrink-0 py-4 bg-dark text-white-50">
                <div class="container text-center">
                    <small>{new Date().getFullYear()} Copyright &copy; Hong Yuan Construction Pte Ltd , UEN:200922798D</small>
                </div>
            </footer>
        </>
    );
};

export default ProjectPage
