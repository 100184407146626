import React from 'react';
import { Button, Result } from 'antd';
import { useState, useEffect } from 'react';
import './index.css'
import axios, { Axios } from 'axios';

const AdminLogin = () => {
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        axios.post(process.env.REACT_APP_BASE_URL + '/api/login', {
            phone: phone,
            password: password
        })
            .then(function (response) {
                console.log(response);
                if (response.data.status === 200) {
                    const item = ({
                        time: new Date().getTime(),
                        user: response.data.user
                    })


                    localStorage.setItem('hongyuanuser', JSON.stringify(item));
                    window.location.href = '/admin';
                } else {
                    alert('Login Failed')
                }
            })
            .catch(function (error) {
                console.log(error);
                alert('Login Failed')
            });

    };
    return (
        <>
            <div className='container'>
                <form
                    style={{ paddingTop: '20%', height: 300, width: 500 }}
                    onSubmit={handleSubmit}
                >
                    <div className="form-outline mb-4">
                        <input
                            type="phone"
                            id="form2Example1"
                            className="form-control"
                            name="phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                        <label className="form-label" htmlFor="form2Example1">
                            Phone
                        </label>
                    </div>

                    <div className="form-outline mb-4">
                        <input
                            type="password"
                            id="form2Example2"
                            className="form-control"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <label className="form-label" htmlFor="form2Example2">
                            Password
                        </label>
                    </div>

                    <button type="submit" className="btn btn-primary btn-block mb-4">
                        Sign in
                    </button>
                </form>
            </div>
        </>
    );
};

export default AdminLogin