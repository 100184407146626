import './App.css';
import { BrowserRouter, Router, Routes, Route, Link, Navigate } from "react-router-dom";
import ContactPage from './Pages/ContactPage/Index';
import Navigation from './Navigation/Navigation';
import HomePage from './Pages/HomePage/Index';
import AboutUsPage from './Pages/AboutUsPage/Index';
import ProjectPage from './Pages/ProjectPage/Index';
import ErrorPage from './Pages/ErrorPage/Index';
import AdminLogin from './Pages/AdminLogin/index';
import Admin from './Admin/index';

import { Layout, Menu, Breadcrumb, Button } from 'antd';
function App() {
  return (
    <BrowserRouter basename='/' >
      {window.location.href.includes('admin') ? null : <Navigation />}
      < Routes >
        <Route path="/" element={<HomePage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/contactus" element={<ContactPage />} />
        <Route path="/projects" element={<ProjectPage />} />
        <Route path="/aboutus" element={<AboutUsPage />} />
        <Route path="/adminlogin" element={<AdminLogin />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes >
    </BrowserRouter >
  );
}
export default App;
