import React from 'react';
import './index.css'
import { Button, Result } from 'antd';
const ErrorPage = () => {
    return (
        <>
            <div class='content'>
                <Result
                    status="404"
                    title="404"
                    style={{ paddingBottom: 500 }}
                    subTitle="Sorry, the page you visited does not exist."
                    extra={<Button type="primary" onClick={
                        () => window.location.href = '/'
                    }>Back Home</Button>}
                />

                <footer id="sticky-footer" class="flex-shrink-0 py-4 bg-dark text-white-50" >
                    <div class="container text-center">
                        <small>{new Date().getFullYear()} Copyright &copy; Hong Yuan Construction Pte Ltd , UEN:200922798D</small>
                    </div>
                </footer>
            </div>
        </>
    );
};

export default ErrorPage
