import React, { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import logo from './logo.png'
import { Layout, Menu, Button, Badge, Row, Drawer, Space, notification, List, Avatar, Radio, Modal } from 'antd';
import { Link } from 'react-router-dom';
import './index.css'
import { HomeOutlined, ProjectOutlined, CopyOutlined, ShoppingCartOutlined, UserOutlined, BellOutlined, LogoutOutlined, MailOutlined, LoginOutlined, SettingOutlined, FormOutlined, TaobaoOutlined, AppstoreOutlined, ClockCircleOutlined, InfoCircleFilled, InfoCircleOutlined, StarOutlined, UserAddOutlined, TeamOutlined } from '@ant-design/icons';
//import { HomeOutlined, ShoppingCartOutlined, UserOutlined, BellOutlined, LogoutOutlined, MailOutlined, LoginOutlined, SettingOutlined, FormOutlined, TaobaoOutlined, AppstoreOutlined, ClockCircleOutlined, InfoCircleFilled, InfoCircleOutlined, StarOutlined, UserAddOutlined, TeamOutlined } from '@ant-design/icons';
const { Header } = Layout;

const items = [
    {
        label: 'Home',
        key: 'mail',
        icon: <HomeOutlined style={{ fontSize: 20 }} />,
        to: '/home'
    },
    {
        label: 'Projects',
        key: 'app',
        icon: <ProjectOutlined style={{ fontSize: 20 }} />,
        to: '/projects'
    },
    {
        label: 'About us',
        key: 'app1',
        icon: <CopyOutlined style={{ fontSize: 20 }} />,
        to: '/aboutus'
    },

];
const Navigation = () => {
    const [current, setCurrent] = useState('mail');
    const onClick = (e) => {
        console.log('click ', e);
        setCurrent(e.key);
    };
    useEffect(() => {
        const pathname = window.location.pathname;
        console.log(pathname);
        if (pathname === '/home') {
            setCurrent('mail')
        } else if (pathname === '/projects') {
            setCurrent('app')
        } else if (pathname === '/aboutus') {
            setCurrent('app1')
        }
    }, [])

    return (
        <>
            <div className="sticky-header">
                <div className="container" style={{ justifyContent: 'center' }}>
                    <Menu mode="horizontal" onClick={onClick} selectedKeys={[current]} style={{ fontSize: '20px', height: 80, paddingTop: 5 }}>
                        <img src={logo} alt="logo" class='logo' style={{ width: 300, height: 70, marginRight: 100 }} />
                        {items.map((item) => (
                            <Menu.Item key={item.key} icon={item.icon} style={{ paddingTop: 10 }} >
                                <Link to={item.to}>{item.label}</Link>
                            </Menu.Item>
                        ))}
                    </Menu>
                </div>
            </div>
        </>
    )
}

export default Navigation