import './index.css'
import { useEffect } from 'react';
import React, { useState } from 'react';
import axios, { Axios } from 'axios';
import image from './constructionCompany.jpg'
import laying from './laying.jpg'
import { useNavigate } from 'react-router-dom';
import plasterImage from './plastring.jpg';
import sreedingImage from './screeding-icon.jpg';
import AOS from 'aos';
import moment from 'moment';
import 'aos/dist/aos.css'; // Import AOS styles
import Title from 'antd/es/skeleton/Title';
import client1 from './HK.png';
import client2 from './bbr.png';
import client3 from './keong-hong.png';
import client4 from './united-tec-logo.png';
import client5 from './soilbuild_0.png';
import client6 from './kgl.png';
import client7 from './santarli.jpg';
import client8 from './dragas.jpg';
import showcase from './showcase1.jpg';

const HomePage = () => {

    const navigate = useNavigate();
    const [allProjects, setAllProjects] = useState([])
    useEffect(() => {
        AOS.init({
            duration: 1000, // Set animation duration (in milliseconds)
            offset: 100,   // Set the offset (in pixels) from the bottom of the window
        });

        axios.get(process.env.REACT_APP_BASE_URL + '/api/projects').then(res => {
            const sortedProjects = res.data
                .map(project => ({
                    ...project,
                    startDate: moment(project.startDate).format('YYYY-MM-DD'),
                    endDate: moment(project.endDate).format('YYYY-MM-DD')
                }))
                .sort((a, b) => moment(b.endDate).toDate() - moment(a.endDate).toDate())
                .slice(0, 3);
            setAllProjects(sortedProjects)
        })

    }, []);

    const [formData, setFormData] = useState({
        name: '',
        emailAddress: '',
        message: '',
    });
    const currentDate = new Date();
    const [formErrors, setFormErrors] = useState({
        name: '',
        emailAddress: '',
        message: '',
    });

    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [submitError, setSubmitError] = useState(false);

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({
            ...formData,
            [id]: value,
        });
    };

    const handleSubmit = async (e) => {

        e.preventDefault();

        // Add form validation logic here
        const errors = {};

        // Example: Check if name is empty
        if (!formData.name.trim()) {
            errors.name = 'Name is required.';
        }

        // Example: Check if emailAddress is a valid email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!formData.emailAddress.trim() || !emailRegex.test(formData.emailAddress)) {
            errors.emailAddress = 'Email Address is not valid.';
        }

        // Example: Check if message is empty
        if (!formData.message.trim()) {
            errors.message = 'Message is required.';
        }

        // Set errors and stop submission if there are validation errors
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            return;
        }

        // If validation passes, you can submit the form data
        try {
            // Make your API call or form submission logic here
            axios.post(process.env.REACT_APP_BASE_URL + '/api/postQuery', formData
            ).then(res => {
                console.log(res);
                console.log(res.data);
            })
            // Reset the form
            setFormData({
                name: '',
                emailAddress: '',
                message: '',
            });
            // Simulating a successful submission
            setSubmitSuccess(true);
            setSubmitError(false);
        } catch (error) {
            // Handle submission error
            setSubmitSuccess(false);
            setSubmitError(true);
        }
    };


    return (
        <>
            <div class="row"  >
                <img src={image} />
            </div>
            <br />

            <div class="container" data-aos="fade-up" >
                <h1 class="mx-auto d-table my-5 text-secondary">Our Profile</h1>
                <div class="row">
                    <div class="col-lg-6" data-aos="fade-right">
                        <h2>HONG YUAN CONSTRUCTION PTE LTD</h2>
                        <br />
                        <p>Hong Yuan is incorporated in 2012, we grow steadily and complete more than <a href="/projects" target="_blank">20 big projects</a> along the way, we have strongly-tied business relationships with over reputational clients, we also have showed our appreciation to the society through various charity events <a href="/aboutus" target="_blank">details</a>.</p>
                    </div>
                    <div class="col-lg-6 pt-4 pt-lg-0" data-aos="fade-left">
                        <p>
                            Our company has gained trust from many clients by upholding high standards. We prioritize and focus on three regulations
                        </p>
                        <ul>
                            <li><i class="ri-check-double-line"></i> Professionalism</li>
                            <li><i class="ri-check-double-line"></i> Responsibility</li>
                            <li><i class="ri-check-double-line"></i> Quality</li>
                        </ul>
                        <p class="fst-italic">
                            We have earned a good reputation in construction for past years, we will continue to build singapore as dream home.
                        </p>
                    </div>
                </div>

            </div>
            <div class="container" style={{ paddingTop: 20 }} >
                <h1 class="mx-auto d-table my-5 text-secondary">Our Services</h1>
                <section class="container pt-3 mb-3" data-aos="fade-up">

                    <br />
                    <br />
                    <div class="row pt-5 mt-30" >

                        <div class="col-lg-4 col-sm-6 mb-30 pb-5">
                            <a class="card" style={{ height: 200 }}>
                                <div class=" bg-white  mx-auto text-center" style={{ width: '85px', height: '85px', marginTop: '-45px' }}><img src={plasterImage}></img></div>
                                <div class="card-body text-center" >
                                    <h3 class="card-title pt-1">Plastring</h3>
                                    <p class="card-text text-sm">We do palstering to coat and protect walls, ceilings.</p>
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-4 col-sm-6 mb-30 pb-5" >
                            <a class="card" style={{ height: 200 }}>
                                <div class=" bg-white  mx-auto text-center" style={{ width: '90px', height: '90px', marginTop: '-45px' }}><img src={sreedingImage}></img></div>
                                <div class="card-body text-center">
                                    <h3 class="card-title pt-1">Screeding</h3>
                                    <p class="card-text text-sm">Screed to form a smooth level surface for receving a floor finishing.</p>
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-4 col-sm-6 mb-30 pb-5" >
                            <a class="card" style={{ height: 200 }}>
                                <div class=" bg-white  mx-auto text-center" style={{ width: '90px', height: '90px', marginTop: '-45px' }}><img src={laying}></img></div>
                                <div class="card-body text-center">
                                    <h3 class="card-title pt-1">Installation of Tiles</h3>
                                    <p class="card-text text-sm">Professional worker in laying tiles,marble and granite etc in the interior of units.</p>
                                </div>
                            </a>
                        </div>
                    </div >
                </section >
            </div >
            <div class="container" style={{ paddingTop: 20 }} >
                <h1 class="mx-auto d-table my-5 text-secondary">Our Achievement</h1>
                <div class="main-content" data-aos="fade-up">
                    <div class="header bg-gradient-primary pb-8  pt-md-8">
                        <div class="container-fluid">
                            <h2 class="mb-5 text-white">Stats Card</h2>
                            <div class="header-body">
                                <div class="row">
                                    <div class="col-xl-3 col-lg-6">
                                        <div class="card card-stats mb-4 mb-xl-0">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col">
                                                        <h5 class="card-title text-uppercase text-muted mb-0">Projects</h5>
                                                        <span class="h2 font-weight-bold mb-0">20+</span>
                                                    </div>
                                                    <div class="col-auto">
                                                        <div class="icon icon-shape bg-danger text-white rounded-circle shadow">
                                                            <i class="fas fa-chart-bar"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br></br>
                                                {/* <p class="mt-3 mb-0 text-muted text-sm">
                                                    <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> HDBs , Condominium and Hotels </span>
                                                    <span class="text-nowrap">Since 2012</span>
                                                </p> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-6">
                                        <div class="card card-stats mb-4 mb-xl-0">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col">
                                                        <h5 class="card-title text-uppercase text-muted mb-0">Tilers</h5>
                                                        <span class="h2 font-weight-bold mb-0">30+</span>
                                                    </div>
                                                    <div class="col-auto">
                                                        <div class="icon icon-shape bg-warning text-white rounded-circle shadow">
                                                            <i class="fas fa-chart-pie"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br></br>
                                                {/* <p class="mt-3 mb-0 text-muted text-sm">
                                                    <span class="text-danger mr-2"><i class="fas fa-arrow-down"></i> Workers ,Skilled </span>
                                                    <span class="text-nowrap">Experienced</span>
                                                </p> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-6">
                                        <div class="card card-stats mb-4 mb-xl-0">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col">
                                                        <h5 class="card-title text-uppercase text-muted mb-0">Workers</h5>
                                                        <span class="h2 font-weight-bold mb-0">60+</span>
                                                    </div>
                                                    <div class="col-auto">
                                                        <div class="icon icon-shape bg-yellow text-white rounded-circle shadow">
                                                            <i class="fas fa-users"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br></br>
                                                {/* <p class="mt-3 mb-0 text-muted text-sm">
                                                    <span class="text-warning mr-2"><i class="fas fa-arrow-down"></i> Hard working </span>
                                                    <span class="text-nowrap">Dedlicated</span>
                                                </p> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-6">
                                        <div class="card card-stats mb-4 mb-xl-0">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col">
                                                        <h5 class="card-title text-uppercase text-muted mb-0">Contract Value</h5>
                                                        <span class="h2 font-weight-bold mb-0">20 Millions +</span>
                                                    </div>
                                                    <div class="col-auto">
                                                        <div class="icon icon-shape bg-info text-white rounded-circle shadow">
                                                            <i class="fas fa-percent"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br></br>
                                                {/* <p class="mt-3 mb-0 text-muted text-sm">
                                                    <span class="text-success mr-2"><i class="fas fa-arrow-up"></i> Much </span>
                                                    <span class="text-nowrap">Renvune</span>
                                                </p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="py-5 py-xl-6" style={{ marginTop: 100 }}>
                    <div class="container mb-5 mb-md-6">
                        <div class="row justify-content-md-center">
                            <div class="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6 text-center">
                                <h1 class="mx-auto d-table my-5 text-secondary">Our Clients</h1>
                                <p class="text-secondary mb-4 mb-md-5">We have established a long-lasting cooperation with many reputational construction companies in Singapore</p>
                                <hr class="w-50 mx-auto mb-0 text-secondary" />
                            </div>
                        </div>
                    </div>

                    <div class="container overflow-hidden" data-aos="fade-up">
                        <div class="row gy-5 gy-md-6">
                            <div class="col-6 col-md-3 align-self-center text-center">
                                <img src={client1} alt="client" style={{ width: 400, height: 200 }} />
                            </div>
                            <div class="col-6 col-md-3 align-self-center text-center">
                                <img src={client2} alt="client" style={{ width: 400, height: 200 }} />
                            </div>
                            <div class="col-6 col-md-3 align-self-center text-center">
                                <img src={client3} alt="client" style={{ width: 400, height: 200 }} />
                            </div>
                            <div class="col-6 col-md-3 align-self-center text-center">
                                <img src={client4} alt="client" style={{ width: 400, height: 200 }} />
                            </div>
                            <div class="col-6 col-md-3 align-self-center text-center">
                                <img src={client5} alt="client" style={{ width: 400, height: 200 }} />
                            </div>
                            <div class="col-6 col-md-3 align-self-center text-center">
                                <img src={client6} alt="client" style={{ width: 400, height: 200 }} />
                            </div>
                            <div class="col-6 col-md-3 align-self-center text-center">
                                <img src={client7} alt="client" style={{ width: 400, height: 200 }} />
                            </div>
                            <div class="col-6 col-md-3 align-self-center text-center">
                                <img src={client8} alt="client" style={{ width: 300, height: 200 }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container" style={{ marginTop: 100 }}>

                    <h1 class="mx-auto d-table my-5 text-secondary " >Recent Projects</h1>
                    <div class="py-5 py-xl-6">
                        <div class='row justify-content-md-center' data-aos="fade-up" >

                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                            {allProjects.map(project => (
                                <div class="col-md-4">

                                    <div class="card">
                                        <img src={showcase} class="card-img-top" style={{ height: 200, width: 440 }} alt="Image 3" />
                                        <div class="card-body">
                                            <a href={project.link}><h5 class="card-title">{project.title}</h5></a>
                                            <p class="card-text">{project.description}</p>
                                        </div>
                                        <div class="card-footer">
                                            Status - {(new Date(project.endDate) < currentDate)
                                                ? <span class="badge-success">End at : {project.endDate}</span>
                                                : <span class=" badge-danger" style={{ color: 'red' }}>Ongoing</span>
                                            }
                                        </div>
                                    </div>
                                </div>


                            ))}




                        </div>
                    </div>
                    <p class="d-flex justify-content-end texthover" style={{ fontSize: 20, marginTop: -10 }} onClick={
                        () => {
                            navigate('/projects')
                        }
                    }>{'More ->'}</p>
                </div>
                <br></br>
                <br></br>
                <hr></hr>
                <div style={{ paddingTop: 100, paddingBottom: 30 }}>
                    <div class="row justify-content-center">
                        <div class="col-xl-10">
                            <div class="card border-0 rounded-3 shadow-lg overflow-hidden">
                                <div class="card-body p-0">
                                    <div class="row g-0">
                                        <div class="col-sm-6 d-none d-sm-block bg-image">
                                            <img src='https://picsum.photos/530/530'></img>
                                        </div>
                                        <div class="col-sm-6 p-4">
                                            <div class="text-center">
                                                <h1 class="mx-auto d-table my-5 text-secondary ">Wanna talk to us?</h1>
                                                <br></br>
                                                {/* <p class="mb-4 text-muted">Split layout contact form</p> */}
                                            </div>
                                            <form id="contactForm" data-sb-form-api-token="API_TOKEN" onSubmit={handleSubmit}>
                                                <div className="form-floating mb-3">
                                                    <input
                                                        className={`form-control ${formErrors.name ? 'is-invalid' : ''}`}
                                                        id="name"
                                                        type="text"
                                                        placeholder="Name"
                                                        value={formData.name}
                                                        onChange={handleChange}
                                                    />
                                                    <label htmlFor="name">Name</label>
                                                    <div className="invalid-feedback">{formErrors.name}</div>
                                                </div>

                                                {/* Email Address Input */}
                                                <div className="form-floating mb-3">
                                                    <input
                                                        className={`form-control ${formErrors.emailAddress ? 'is-invalid' : ''}`}
                                                        id="emailAddress"
                                                        type="email"
                                                        placeholder="Email Address"
                                                        value={formData.emailAddress}
                                                        onChange={handleChange}
                                                    />
                                                    <label htmlFor="emailAddress">Email Address</label>
                                                    <div className="invalid-feedback">{formErrors.emailAddress}</div>
                                                </div>

                                                {/* Message Input */}
                                                <div className="form-floating mb-3">
                                                    <textarea
                                                        className={`form-control ${formErrors.message ? 'is-invalid' : ''}`}
                                                        id="message"
                                                        placeholder="Message"
                                                        style={{ height: 100 }}
                                                        value={formData.message}
                                                        onChange={handleChange}
                                                    />
                                                    <label htmlFor="message">Message</label>
                                                    <div className="invalid-feedback">{formErrors.message}</div>
                                                </div>

                                                {/* Success and Error Messages */}
                                                <div className={`text-center mb-3 ${submitSuccess ? '' : 'd-none'}`} id="submitSuccessMessage">
                                                    <div className="fw-bolder " style={{ color: "#008000" }}>we have received your inquery!</div>

                                                </div>

                                                <div className={`text-center text-danger mb-3 ${submitError ? '' : 'd-none'}`} id="submitErrorMessage">
                                                    Error sending message!
                                                </div>

                                                {/* Submit Button */}
                                                <div className="d-grid">
                                                    <button className="btn btn-primary btn-lg" type="submit" >
                                                        Submit
                                                    </button>
                                                </div>
                                            </form>


                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>




            </div >



            <footer id="sticky-footer" class="flex-shrink-0 py-4 bg-dark text-white-50">
                <div class="container text-center">
                    <small>{new Date().getFullYear()} Copyright &copy; Hong Yuan Construction Pte Ltd , UEN:200922798D</small>
                </div>
            </footer>

        </>
    );
};

export default HomePage
