
import './index.css';
import image from './avatar.jpg';
import Tobaedd from './Images-to-be-added-soon.jpg';
const AboutUsPage = () => {
    return (
        <>

            <iframe class='inageStyle' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15954.263400566693!2d103.83455523297617!3d1.435674983247176!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da1460eac7ad97%3A0xe7353622d4e32e26!2sSingapore%20768159!5e0!3m2!1sen!2ssg!4v1629792923002!5m2!1sen!2ssg" frameborder="0" allowfullscreen></iframe>
            <div class="container" >

                <div class="text-center justify-content-center" style={{ paddingTop: 40, paddingBottom: 40 }}>



                    <div class="info-wrap">
                        <div class="row">
                            <div class="col-lg-4 info">

                                <h4>  <i class="bi bi-geo-alt"></i>Location:</h4>
                                <p>2 Yishun Industrial Street 1<br />#03-25 Northpoint Bizhub<br />Singapore 768159</p>
                            </div>

                            <div class="col-lg-4 info mt-4 mt-lg-0">

                                <h4 >  <i class="bi bi-envelope"></i>Email:</h4>
                                <p>hongyuansg@hotmail.com</p>
                            </div>

                            <div class="col-lg-4 info mt-4 mt-lg-0">

                                <h4>  <i class="bi bi-phone"></i>Call:</h4>
                                <p>+65 63842814</p>
                                <p>+65 94551268</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr></hr>

            <div class="container py-5 ">

                <div class="text-center justify-content-center " >
                    <h1 class="mx-auto d-table my-5 text-secondary" >Our team</h1>
                </div>

                <div class="row text-center">

                    <div class="col-xl-3 col-sm-6 mb-5">
                        <div class="bg-white rounded shadow-sm py-5 px-4"><img src={image} alt="" width="100" class="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm" />
                            <h5 class="mb-0">Li xiaohong</h5><span class="small text-uppercase text-muted">Founder</span>
                            <ul class="social mb-0 list-inline mt-3">
                                <li class="list-inline-item"><i class="fa fa-facebook-f" />94551268</li>

                            </ul>
                        </div>
                    </div>

                    <div class="col-xl-3 col-sm-6 mb-5">
                        <div class="bg-white rounded shadow-sm py-5 px-4"><img src={image} alt="" width="100" class="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm" />
                            <h5 class="mb-0">Cao Zhang Lin</h5><span class="small text-uppercase text-muted">Site Manager</span>
                            <ul class="social mb-0 list-inline mt-3">
                                <li class="list-inline-item"><i class="fa fa-facebook-f" />94870716</li>

                            </ul>
                        </div>
                    </div>


                    <div class="col-xl-3 col-sm-6 mb-5">
                        <div class="bg-white rounded shadow-sm py-5 px-4"><img src={image} alt="" width="100" class="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm" />
                            <h5 class="mb-0">Wang Jun Ya</h5><span class="small text-uppercase text-muted">Office Manager</span>
                            <ul class="social mb-0 list-inline mt-3">
                                <li class="list-inline-item"><i class="fa fa-facebook-f" />94870716</li>

                            </ul>
                        </div>
                    </div>

                    <div class="col-xl-3 col-sm-6 mb-5">
                        <div class="bg-white rounded shadow-sm py-5 px-4"><img src={image} alt="" width="100" class="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm" />
                            <h5 class="mb-0">Li Jian</h5><span class="small text-uppercase text-muted">IT Manager</span>
                            <ul class="social mb-0 list-inline mt-3">
                                <li class="list-inline-item"><i class="fa fa-facebook-f" />94870716</li>

                            </ul>
                        </div>
                    </div>

                </div>

                <div class="row" id="feedback" style={{ paddingTop: 30 }}>
                    <div class="text-center justify-content-center " >
                        <h1 class="mx-auto d-table my-5 text-secondary" >Feed Back to the community</h1>

                    </div>
                    <br />
                    <div class="col-lg-4 col-md-12 mb-4 mb-lg-0">
                        <img src={Tobaedd}
                            class="w-100 shadow-1-strong rounded mb-4"
                            alt="" />

                        <img src={Tobaedd}
                            class="w-100 shadow-1-strong rounded mb-4"
                            alt="" />
                    </div>

                    <div class="col-lg-4 mb-4 mb-lg-0">
                        <img src={Tobaedd}
                            class="w-100 shadow-1-strong rounded mb-4"
                            alt="" />

                        <img src={Tobaedd}
                            class="w-100 shadow-1-strong rounded mb-4"
                            alt="" />
                    </div>

                    <div class="col-lg-4 mb-4 mb-lg-0">
                        <img src={Tobaedd}
                            class="w-100 shadow-1-strong rounded mb-4"
                            alt="" />

                        <img src={Tobaedd}
                            class="w-100 shadow-1-strong rounded mb-4"
                            alt="" />
                    </div>
                </div>
            </div>
            <footer id="sticky-footer" class="flex-shrink-0 py-4 bg-dark text-white-50">
                <div class="container text-center">
                    <small>{new Date().getFullYear()} Copyright &copy; Hong Yuan Construction Pte Ltd , UEN:200922798D</small>
                </div>
            </footer>
        </>
    );
};

export default AboutUsPage
