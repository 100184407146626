import React, { useState, useEffect } from 'react';
import { UserOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Form, DatePicker, Input, message, Modal, Table, Tabs, InputNumber } from 'antd';
import axios from 'axios';
import moment from 'moment';
import dayjs from 'dayjs';



const Admin = () => {
    const projects = [
        {
            title: '项目名称',
            width: 100,
            dataIndex: 'title',
            key: 'title',
            sorter: true,

        },
        {
            title: '全称',
            width: 100,
            dataIndex: 'description',
            key: 'description',

        },
        {
            title: '客户名称',
            width: 100,
            dataIndex: 'client',
            key: 'client',

        },
        {
            title: '链接',
            width: 100,
            dataIndex: 'link',
            key: 'link',

        },
        {
            title: '开始时间',
            width: 100,
            dataIndex: 'startDate',
            key: 'startDate',

        },
        {
            title: '结束时间',
            width: 100,
            dataIndex: 'endDate',
            key: 'endDate',

        },

        {
            title: '项目金额',
            width: 100,
            dataIndex: 'contractValue',
            key: 'contractValue',

        },

        {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            width: 100,
            render: (
                text,
                record
            ) => <><a href style={{ color: 'green' }} onClick={() => deleteProject(record._id)} >删除</a> <Button type="primary" onClick={() =>
                modifyProjectOpen(record)

            }>
                <EditOutlined />
                更改
            </Button></>,
        },
    ];

    const worker = [
        {
            title: 'Name',

            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'wp',

            dataIndex: 'wp',
            key: 'wp',

        },
        {
            title: 'expired',

            dataIndex: 'expired',
            key: 'expired',
            sorter: (a, b) => {
                const dateA = new Date(a.expired);
                const dateB = new Date(b.expired);

                return dateA - dateB;
            },
            sortDirections: ['descend', 'ascend'],


        },

        {
            title: '操作',
            dataIndex: 'operation',
            key: 'operation',
            render: (text, record) => (
                <><a href style={{ color: 'green' }} onClick={() => handleDelete(record._id)}>删除</a>
                    <Button type="primary" style={{ marginLeft: 20 }} onClick={() => modifyWorkerOpen(record)}>修改</Button></>
            ),
        },
    ];

    const users = [
        {
            title: 'Name',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: '注册时间',
            dataIndex: 'date',
            key: 'date',

        },
        {
            title: '操作',
            dataIndex: 'operation',
            key: 'operation',
            render: (text, record) => (
                <><a href style={{ color: 'green' }} onClick={() => handleUserDelete(record._id)}>删除</a></>
            ),
        }
    ];

    const query = [
        {
            title: '主题',
            width: 100,
            dataIndex: 'name',
            key: 'name',

        },
        {
            title: '邮件地址',
            width: 100,
            dataIndex: 'emailAddress',
            key: 'emailAddress',

        },
        {
            title: '信息内容',
            width: 100,
            dataIndex: 'message',
            key: 'message',

        },
        {
            title: '时间',
            width: 100,
            dataIndex: 'logDate',
            key: 'logDate',

        },
        {
            title: '操作',
            key: 'operation',

            width: 100,
            render: (text, record) => <>  <a href style={{ color: 'green' }} onClick={() => handleQueryDelete(record._id)}>删除</a></>,
        },
    ];

    const [form] = Form.useForm();
    const [addProjectForm] = Form.useForm();
    const [modifyProjectForm] = Form.useForm();

    const [workerList, setWorkerList] = useState([]);
    const [workerCreateName, setWorkerCreateName] = useState('');
    const [workerCreateWp, setWorkerCreateWp] = useState('');
    const [workerCreateDate, setWorkerCreateDate] = useState('');
    const [adminUser, setadminUser] = useState([]);
    const [formQuery, setformQuery] = useState([]);
    const [isModifyWorkerModalOpen, setIsModifyWorkerModalOpen] = useState(false);

    const [modifyWorker, setModifyWorker] = useState({
        name: '',
        wp: '',
        expired: ''
    });
    const [selectedWorkerID, setSelectedWorkerID] = useState('');
    const [isProjectModalOpen, setIsProjectModalOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [allProjects, setAllProjects] = useState([]);
    const [changePasswordOpen, setChangePasswordOpen] = useState(false);
    const [changePasswordState, setChangePasswordState] = useState("");
    const [isModifyProjectModalOpen, setIsModifyProjectModalOpen] = useState(false);
    const loginDetails = JSON.parse(localStorage.getItem('hongyuanuser'));
    const [modifyWorkerForm] = Form.useForm();
    const [addProjectState, setAddProjectState] = useState({
        title: '',
        link: '',
        description: '',
        client: '',
        money: '',
        startdate: '',
        enddate: ''
    })

    // const [modifyProjectState, setModifyProjectState] = useState({
    //     title: '',
    //     link: '',
    //     description: '',
    //     client: '',
    //     money: '',
    //     startdate: '',
    //     enddate: ''
    // })

    const [modifyProjectInitialValue, setModifyProjectInitialValue] = useState({
        title: '',
        link: '',
        description: '',
        client: '',
        contractValue: '',
        startDate: '',
        endDate: ''
    })

    const deleteProject = (id) => {
        axios.delete(process.env.REACT_APP_BASE_URL + `/api/project/${id}`).then(res => {
            setAllProjects(allProjects.map(project => {
                return {
                    ...project,
                    startDate: moment(project.startDate).format('YYYY-MM-DD'),
                    endDate: moment(project.endDate).format('YYYY-MM-DD')
                }
            }).filter(project => project._id !== id))


            message.success('删除成功')
        })
    }

    const handleQueryDelete = (id) => {

        axios.delete(process.env.REACT_APP_BASE_URL + `/api/deleteQuery/${id}`).then(res => {
            setformQuery(formQuery.filter(query => query._id !== id))
            message.success('删除成功')
        })
    }
    const modifyProjectOpen = (project) => {

        setIsModifyProjectModalOpen(true);
        setModifyProjectInitialValue({
            title: project.title,
            link: project.link,
            description: project.description,
            client: project.client,
            contractValue: project.contractValue,
            startDate: project.startDate,
            endDate: project.endDate,
            _id: project._id
        });
    }

    const modifyWorkerOpen = (worker) => {
        setModifyWorker({
            name: worker.name,
            wp: worker.wp,
            expired: worker.expired
        });
        setSelectedWorkerID(worker._id);
        setIsModifyWorkerModalOpen(true);
    }


    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (loginDetails === null) {
            window.location.href = '/adminlogin';
            return;
        }
        const now = new Date().getTime();

        if (now - loginDetails.time > 60 * 60 * 5000) {
            localStorage.removeItem('hongyuanuser');
            window.location.href = '/adminlogin';
            return;
        }


        axios.get(process.env.REACT_APP_BASE_URL + '/api/workers').then(res => {
            setWorkerList(res.data)
        })
        axios.get(process.env.REACT_APP_BASE_URL + '/api/registerUser').then(res => {
            setadminUser(res.data.filter(user => user.phone !== '94870716'))
        }

        )
        axios.get(process.env.REACT_APP_BASE_URL + '/api/postQuery').then(res => {
            setformQuery(res.data)
        })

        axios.get(process.env.REACT_APP_BASE_URL + '/api/projects').then(res => {
            setAllProjects(res.data.map(project => {
                return {
                    ...project,
                    startDate: moment(project.startDate).format('YYYY-MM-DD'),
                    endDate: moment(project.endDate).format('YYYY-MM-DD')
                }
            }
            ))
        })


        modifyProjectForm.setFieldsValue({
            title: modifyProjectInitialValue.title,
            link: modifyProjectInitialValue.link,
            description: modifyProjectInitialValue.description,
            client: modifyProjectInitialValue.client,
            contractValue: modifyProjectInitialValue.contractValue,
            startDate: modifyProjectInitialValue.startDate,
            endDate: modifyProjectInitialValue.endDate
        });

        modifyWorkerForm.setFieldsValue({
            name: modifyWorker.name,
            wp: modifyWorker.wp,
            expired: modifyWorker.expired,
        });
        // alert(modifyWorker.expired)

    }, [modifyProjectInitialValue, modifyWorker, modifyWorkerForm, modifyProjectForm])

    const postWorker = () => {
        axios.post(process.env.REACT_APP_BASE_URL + '/api/workers', {
            name: workerCreateName,
            wp: workerCreateWp,
            expired: workerCreateDate
        }).then(res => {
            setWorkerList([...workerList, res.data])
            message.success('添加成功')
            setIsModalOpen(false);
        })
    }

    const [isUserModalOpen, setIsUserModalOpen] = useState(false);
    const showUserModal = () => {
        setIsUserModalOpen(true);
    }


    const handleUserModalCancel = () => {
        setIsUserModalOpen(false);
    }
    const handleModifyWorkerCancel = () => {
        setIsModifyWorkerModalOpen(false);
    }

    const postUser = () => {

        axios.post(process.env.REACT_APP_BASE_URL + '/api/registerUser', {
            phone: phone,
            password: password,
        }).then(res => {
            setadminUser([...adminUser, res.data])
            message.success('添加成功')
            setIsUserModalOpen(false);

        })

    }
    const modifyWorkerChange = () => {
        if (modifyWorker.name.trim === '' || modifyWorker.wp === '' || modifyWorker.expired.trim === '') {
            message.error('请填写完整信息')
            return
        }

        // alert(modifyWorker.expired)

        axios.put(process.env.REACT_APP_BASE_URL + `/api/worker/${selectedWorkerID}`, {
            name: modifyWorker.name,
            wp: modifyWorker.wp,
            expired: modifyWorker.expired
        }).then(res => {
            setWorkerList(workerList.map(worker => {
                if (worker._id === selectedWorkerID) {
                    return {
                        ...worker,
                        name: modifyWorker.name,
                        wp: modifyWorker.wp,
                        expired: modifyWorker.expired
                    }
                } else {
                    return worker
                }
            }
            ))
            message.success('修改成功')
            setIsModifyWorkerModalOpen(false);
        })

    }

    const dateModifyChange = (date, dateString) => {
        setModifyWorker({
            ...modifyWorker,
            expired: dateString
        })
    }

    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');

    const changedPasswprdCancel = () => {
        setChangePasswordOpen(false);
    }

    const handleUserDelete = (id) => {

        axios.delete(process.env.REACT_APP_BASE_URL + `/api/deleterUser/${id}`).then(res => {
            setadminUser(adminUser.filter(user => user._id !== id))
            message.success('删除成功')
        })
    }

    const handleDelete = (id) => {
        axios.delete(process.env.REACT_APP_BASE_URL + `/api/worker/${id}`).then(res => {
            setWorkerList(workerList.filter(worker => worker._id !== id))
            message.success('删除成功')
        })
    }
    const dateChange = (date, dateString) => {
        setWorkerCreateDate(dateString);
    }

    const handleProjectCancel = () => {
        setIsProjectModalOpen(false);
    }

    const changePassword = () => {

        if (changePasswordState.trim() === '') {
            message.error('请输入密码')
            return
        }
        axios.put(process.env.REACT_APP_BASE_URL + `/api/changePassword/${loginDetails.user._id}`, {
            password: changePasswordState
        }).then(res => {

            message.success('修改成功,请重新登录')
            setChangePasswordOpen(false);
            setTimeout(() => {

                localStorage.removeItem('hongyuanuser');
                window.location.href = '/adminlogin';
            }, 2000);

        })


    }


    const addProject = () => {

        axios.post(process.env.REACT_APP_BASE_URL + '/api/projects', {
            title: addProjectState.title,
            link: addProjectState.link,
            description: addProjectState.description,
            client: addProjectState.client,
            contractValue: addProjectState.money,
            startDate: addProjectState.startdate,
            endDate: addProjectState.enddate
        }).then(res => {
            message.success('添加成功');
            const returnData = {
                ...res.data,
                startDate: moment(res.data.startDate).format('YYYY-MM-DD'),
                endDate: moment(res.data.endDate).format('YYYY-MM-DD')
            }

            setAllProjects([...allProjects, returnData]);
            setIsProjectModalOpen(false);
            setAddProjectState({
                title: '',
                link: '',
                description: '',
                client: '',
                money: '',
                startdate: '',
                enddate: ''
            })
        })
    }

    const handleModifyProjectCancel = () => {
        setIsModifyProjectModalOpen(false);
    }

    const ModifyProject = () => {

        axios.put(process.env.REACT_APP_BASE_URL + `/api/project/${modifyProjectInitialValue._id}`, {
            title: modifyProjectInitialValue.title,
            link: modifyProjectInitialValue.link,
            description: modifyProjectInitialValue.description,
            client: modifyProjectInitialValue.client,
            contractValue: modifyProjectInitialValue.contractValue,
            startDate: modifyProjectInitialValue.startDate,
            endDate: modifyProjectInitialValue.endDate
        }).then(res => {
            message.success('修改成功');
            const returnData = {
                ...res.data,
                startDate: moment(res.data.startDate).format('YYYY-MM-DD'),
                endDate: moment(res.data.endDate).format('YYYY-MM-DD')
            }

            setAllProjects(allProjects.map(project => {
                if (project._id === modifyProjectInitialValue._id) {
                    return returnData
                } else {
                    return project
                }
            }))
            setIsModifyProjectModalOpen(false);
        })

    }

    const ProjectStartChange = (date, dateString) => {
        setAddProjectState({
            ...addProjectState,
            startdate: dateString
        })
    }

    const ProjectEndDateChange = (date, dateString) => {
        setAddProjectState({
            ...addProjectState,
            enddate: dateString
        })
    }
    const openAddProejctModal = () => {
        setIsProjectModalOpen(true)

    }

    const ModifyProjectStartChange = (date, dateString) => {
        setModifyProjectInitialValue({
            ...modifyProjectInitialValue,
            startDate: dateString
        })
    }

    const ModifyProjectEndDateChange = (date, dateString) => {
        setModifyProjectInitialValue({
            ...modifyProjectInitialValue,
            endDate: dateString
        })
    }

    return (
        <>

            <Modal title="Add Worker" open={isModalOpen} onOk={form.submit} onCancel={handleCancel}>
                <Form
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 12,
                    }}
                    style={{
                        maxWidth: 600,
                        paddingTop: 60
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    form={form}
                    onFinish={postWorker}
                    // onFinishFailed={postWorker}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Name"
                        name="Name"
                        rules={[
                            {
                                required: true,
                                message: 'Please input name!',
                            },
                        ]}
                        onChange={e => setWorkerCreateName(e.target.value)}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="wp"
                        name="wp"
                        rules={[
                            {
                                required: true,
                                message: 'Please input wp!',
                            },
                        ]}
                        onChange={e => setWorkerCreateWp(e.target.value)}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label="Start Date">
                        <DatePicker
                            name='date'
                            style={{ width: '100%' }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input expired date!',
                                },
                            ]}
                            onChange={dateChange
                            }

                        />
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            offset: 4,
                            span: 12,
                        }}
                    >
                    </Form.Item>
                </Form>
            </Modal>

            <Modal title="Add Users" open={isUserModalOpen} onOk={form.submit} onCancel={handleUserModalCancel}>
                <Form
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 12,
                    }}
                    style={{
                        maxWidth: 600,
                        paddingTop: 60
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    form={form}
                    onFinish={postUser}
                    // onFinishFailed={postWorker}
                    autoComplete="off"
                >
                    <Form.Item
                        label="phone"
                        name="phone"
                        rules={[
                            {
                                required: true,
                                message: 'Please input phone!',
                            },
                        ]}
                        onChange={e => setPhone(e.target.value)}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="password"
                        name="password"
                        onChange={e => setPassword(e.target.value)}
                        rules={[
                            {
                                required: true,
                                message: 'Please input password!',
                            },
                        ]}

                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            offset: 4,
                            span: 12,
                        }}
                    >
                    </Form.Item>
                </Form>
            </Modal>

            <Modal title="Modify worker" open={isModifyWorkerModalOpen} onOk={modifyWorkerForm.submit}
                onCancel={handleModifyWorkerCancel} >
                <Form
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 12,
                    }}
                    style={{
                        maxWidth: 600,
                        paddingTop: 60
                    }}

                    form={modifyWorkerForm}
                    onFinish={modifyWorkerChange}
                    // onFinishFailed={postWorker}
                    autoComplete="off"
                >
                    <Form.Item

                        label="Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Please input name!',
                            },
                        ]}
                        onChange={(e) =>
                            setModifyWorker({
                                ...modifyWorker,
                                name: e.target.value
                            })

                        }
                    >
                        <Input defaultValue={modifyWorker.name} />
                    </Form.Item>
                    <Form.Item
                        label="wp"
                        name="wp"

                        rules={[
                            {
                                required: true,
                                message: 'Please input wp!',
                            },
                        ]}
                        onChange={(e) =>
                            setModifyWorker({
                                ...modifyWorker,
                                wp: e.target.value
                            })
                        }
                    >
                        <Input defaultValue={modifyWorker.wp} />
                    </Form.Item>
                    <Form.Item label="Expired Date" >
                        <DatePicker
                            name='expired'

                            // name='date'
                            format="YYYY-MM-DD"
                            defaultValue={dayjs(modifyWorker.expired, "YYYY-MM-DD")}
                            style={{ width: '100%' }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input expired date!',
                                },
                            ]}
                            value={dayjs(modifyWorker.expired, "YYYY-MM-DD")}
                            onChange={dateModifyChange
                            }

                        />
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            offset: 4,
                            span: 12,
                        }}
                    >
                    </Form.Item>
                </Form>
            </Modal>


            <Modal title="Add Projects" open={isProjectModalOpen} onOk={addProjectForm.submit}
                onCancel={handleProjectCancel} >
                <Form
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 12,
                    }}
                    style={{
                        maxWidth: 600,
                        paddingTop: 60
                    }}

                    form={addProjectForm}
                    onFinish={addProject}
                    // onFinishFailed={addProjectCancel}
                    autoComplete="off"
                >
                    <Form.Item

                        label="Title"
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: 'Please input title!',
                            },
                        ]}
                        onChange={e => setAddProjectState({
                            ...addProjectState,
                            title: e.target.value
                        })}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="link"
                        name="link"

                        rules={[
                            {
                                required: true,
                                message: 'Please input link!',
                            },
                        ]}
                        onChange={e => setAddProjectState({
                            ...addProjectState,
                            link: e.target.value
                        })}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Description"
                        name="description"

                        rules={[
                            {
                                required: true,
                                message: 'Please input Description!',
                            },
                        ]}
                        onChange={e => setAddProjectState({
                            ...addProjectState,
                            description: e.target.value
                        })}
                    >
                        <Input.TextArea />
                    </Form.Item>

                    <Form.Item
                        label="Client"
                        name="client"

                        rules={[
                            {
                                required: true,
                                message: 'Please input client!',
                            },
                        ]}
                        onChange={e => setAddProjectState({
                            ...addProjectState,
                            client: e.target.value
                        })}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Contract value"
                        name="money"

                        rules={[
                            {
                                required: true,
                                message: 'Please input contract value!',
                            },
                        ]}
                        onChange={e => setAddProjectState({
                            ...addProjectState,
                            money: e.target.value
                        })}
                    >
                        <InputNumber onChange={(value) => setAddProjectState({ ...addProjectState, money: value })} />
                    </Form.Item>

                    <Form.Item label="Start Date" >
                        <DatePicker
                            name='startdate'

                            // name='date'
                            format="YYYY-MM-DD"
                            style={{ width: '100%' }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input start date!',
                                },
                            ]}
                            onChange={ProjectStartChange}

                        />
                    </Form.Item>

                    <Form.Item label="End Date" >
                        <DatePicker
                            name='enddate'
                            // name='date'
                            format="YYYY-MM-DD"
                            style={{ width: '100%' }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input end date!',
                                },
                            ]}
                            onChange={ProjectEndDateChange}
                        />
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            offset: 4,
                            span: 12,
                        }}
                    >
                    </Form.Item>
                </Form>
            </Modal>


            <Modal title="Modify Projects" open={isModifyProjectModalOpen} onOk={modifyProjectForm.submit}
                onCancel={handleModifyProjectCancel} >
                <Form
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 12,
                    }}
                    style={{
                        maxWidth: 600,
                        paddingTop: 60
                    }}

                    form={modifyProjectForm}
                    onFinish={ModifyProject}
                    // onFinishFailed={addProjectCancel}
                    autoComplete="off"
                >
                    <Form.Item

                        label="Title"
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: 'Please input title!',
                            },
                        ]}

                        onChange={(e) => setModifyProjectInitialValue({
                            ...modifyProjectInitialValue,
                            title: e.target.value
                        })
                        }
                    >
                        <Input
                            defaultValue={modifyProjectInitialValue.title}
                        />
                    </Form.Item>
                    <Form.Item
                        label="link"
                        name="link"

                        rules={[
                            {
                                required: true,
                                message: 'Please input link!',
                            },
                        ]}
                        // initialValue={modifyProjectInitialValue.link}

                        onChange={(e) => setModifyProjectInitialValue({
                            ...modifyProjectInitialValue,
                            link: e.target.value
                        }
                        )}

                    >
                        <Input defaultValue={
                            modifyProjectInitialValue.link
                        } />
                    </Form.Item>

                    <Form.Item
                        label="Description"
                        name="description"

                        rules={[
                            {
                                required: true,
                                message: 'Please input Description!',
                            },
                        ]}
                        // initialValue={modifyProjectInitialValue.description}

                        onChange={(e) =>
                            setModifyProjectInitialValue({
                                ...modifyProjectInitialValue,
                                description: e.target.value
                            })
                        }
                    >
                        <Input.TextArea defaultValue={
                            modifyProjectInitialValue.description
                        }
                        />
                    </Form.Item>

                    <Form.Item
                        label="Client"
                        name="client"

                        rules={[
                            {
                                required: true,
                                message: 'Please input client!',
                            },
                        ]}
                        // initialValue={modifyProjectInitialValue.client}


                        onChange={(e) => setModifyProjectInitialValue({
                            ...modifyProjectInitialValue,
                            client: e.target.value
                        })}
                    >
                        <Input defaultValue={
                            modifyProjectInitialValue.client
                        }


                        />
                    </Form.Item>
                    <Form.Item
                        label="Contract value"
                        name="contractValue"

                        rules={[
                            {
                                required: true,
                                message: 'Please input contract value!',
                            },
                        ]}
                        // initialValue={modifyProjectInitialValue.contractValue}


                        onChange={(e) => setModifyProjectInitialValue({
                            ...modifyProjectInitialValue,
                            contractValue: e.target.value
                        })}
                    >
                        <InputNumber
                            onChange={(value) => setModifyProjectInitialValue({ ...modifyProjectInitialValue, contractValue: value })}
                            defaultValue={
                                modifyProjectInitialValue.contractValue
                            }

                        />
                    </Form.Item>

                    <Form.Item label="Start Date" >
                        <DatePicker
                            name='startDate'
                            onChange={ModifyProjectStartChange}
                            defaultValue={dayjs(modifyProjectInitialValue.startDate, "YYYY-MM-DD")}

                            // name='date'
                            format="YYYY-MM-DD"
                            style={{ width: '100%' }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input start date!',
                                },
                            ]}
                            value={dayjs(modifyProjectInitialValue.startDate, "YYYY-MM-DD")}


                        />
                    </Form.Item>

                    <Form.Item label="End Date" >
                        <DatePicker
                            name='endDate'
                            format="YYYY-MM-DD"
                            defaultValue={dayjs(modifyProjectInitialValue.endDate, "YYYY-MM-DD")}


                            style={{ width: '100%' }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input end date!',
                                },
                            ]}
                            value={dayjs(modifyProjectInitialValue.endDate, "YYYY-MM-DD")}
                            onChange={ModifyProjectEndDateChange}
                        />
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            offset: 4,
                            span: 12,
                        }}
                    >
                    </Form.Item>
                </Form>
            </Modal>


            <Modal title="Change Password" open={changePasswordOpen} onOk={form.submit}
                onCancel={changedPasswprdCancel} >
                <Form
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 12,
                    }}
                    style={{
                        maxWidth: 600,
                        paddingTop: 60
                    }}

                    form={form}
                    onFinish={changePassword}
                    // onFinishFailed={addProjectCancel}
                    autoComplete="off"
                >
                    <Form.Item

                        label="password"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input title!',
                            },
                        ]}
                        onChange={e => setChangePasswordState(e.target.value)}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            offset: 4,
                            span: 12,
                        }}
                    >
                    </Form.Item>
                </Form>
            </Modal>




            <div class='container-fluid' style={{ backgroundColor: '#f0f0f0' }}>

                <div class="container-fluid" style={{ paddingTop: 20 }}>
                    <div style={{ paddingTop: '25px', paddingBottom: '20px', marginBottom: '20px', backgroundColor: 'White', paddingRight: '25px', paddingLeft: '25px' }}>
                        <h3>This is the Admin Page  <Button type="primary" style={{ float: 'right' }} onClick={
                            () => {
                                localStorage.removeItem('hongyuanuser');
                                window.location.href = '/adminlogin';
                            }
                        }>Log out</Button></h3>
                    </div>
                    <div style={{ paddingTop: '25px', paddingBottom: '20px', marginBottom: '20px', backgroundColor: 'White', paddingRight: '25px', paddingLeft: '25px' }}>
                        login as :  <UserOutlined />    {loginDetails?.user.phone}  <Button type="primary" style={{ float: 'right' }} onClick={
                            () => setChangePasswordOpen(true)
                        }> Change Password</Button>
                    </div>
                    <div style={{ paddingTop: '25px', paddingBottom: '20px', marginBottom: '20px', backgroundColor: 'White', paddingRight: '25px', paddingLeft: '25px' }}>
                        <Tabs defaultActiveKey="1">
                            <Tabs.TabPane tab="Projects(项目)" key="1">
                                <Button type="primary" onClick={() =>
                                    openAddProejctModal()
                                } ><PlusOutlined /> Add Project</Button>
                                <br></br>
                                <br></br>
                                <Table
                                    columns={projects}
                                    dataSource={allProjects}
                                    scroll={{
                                        x: 1500,
                                        y: 1000,
                                    }}
                                />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Workers(工人)" key="2">
                                <Button type="primary" onClick={
                                    () => showModal()
                                } ><PlusOutlined />Add Worker</Button>   <Input style={{
                                    width: 300,
                                    float: 'right'
                                }} placeholder="search by name" onChange={(e) => {
                                    if (e.target.value !== '') {
                                        setWorkerList(workerList.filter(worker => worker.name.includes(e.target.value)))
                                    } else {
                                        axios.get('http://localhost:3000/api/workers').then(res => {
                                            setWorkerList(res.data)
                                        })

                                    }

                                }}>
                                </Input>
                                <br></br>
                                <br></br>
                                <Table
                                    columns={worker}
                                    dataSource={workerList.map((worker) => {
                                        return {
                                            _id: worker._id,
                                            name: worker.name,
                                            wp: worker.wp,
                                            expired: new Date(worker.expired).toISOString().split('T')[0]
                                        }

                                    })
                                    }
                                    scroll={{
                                        x: 1500,
                                        y: 1000,
                                    }}
                                />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Form(联系我们)" key="3">
                                <Table
                                    columns={query}
                                    dataSource={formQuery}
                                    scroll={{
                                        x: 1500,
                                        y: 1000,
                                    }}
                                />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="All Users(用户)" key="4">
                                <Button type="primary" onClick={
                                    () => showUserModal()
                                } ><PlusOutlined /> Add user</Button>
                                <br></br>
                                <br></br>
                                <Table
                                    columns={users}
                                    dataSource={adminUser.map((user) => {
                                        return {
                                            _id: user._id,
                                            phone: user.phone,
                                            date: new Date(user.date).toISOString().split('T')[0],
                                            status: user.status
                                        }
                                    })
                                    }
                                    scroll={{
                                        x: 1500,
                                        y: 1000,
                                    }}
                                />
                            </Tabs.TabPane>
                        </Tabs>
                    </div>
                </div>
            </div >
        </>
    );
};

export default Admin
